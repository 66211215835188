export const formValidation = {
  methods: {
    checkForm(data, required, requiredTooltip) {
      let empty = false;

      for (const key in required) {
        required[key] = "";
        requiredTooltip[key] = "";

        if (!data[key] || (Array.isArray(data[key]) && !data[key].length)) {
          required[key] = "uc-alert error";
          requiredTooltip[key] = "Campo requerido";
          empty = true;
        }
      }

      if ('email' in data && !this.validEmail(data.email)) {
        required.email = "uc-alert error";
        let emailInvalidArray = requiredTooltip.email.split();
        emailInvalidArray.push("Correo Electrónico Inválido");
        emailInvalidArray = emailInvalidArray.filter((el) => {
          return el != '';
        });
        requiredTooltip.email = emailInvalidArray.join("<br>");
        empty = true;
      }

      return empty;
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
