<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="mb-24"><i class="uc-icon warning-icon">done_all</i> Confirma que tus datos estén correctos </h4>
    </div>
    <div class="col-md-12" v-for="(field, key) in this.fields" :key="key">
      <div class="uc-form-group ml-24">
        <strong>{{ translate[key] }}</strong>
        <p>{{getField(key, field)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Object
  },
  data() {
    return {
      translate: {
        academicActivities: "Actividades Académicas",
        areas: "Áreas",
        condition: "Condición",
        email: "Email",
        fifthPreference: "Quinta Preferencia",
        firstPreference: "Primera Preferencia",
        fourthPreference: "Cuarta Preferencia",
        languages: "Idiomas",
        lawExperience: "Experiencia Laboral",
        name: "Nombre",
        otherLanguage: "Otro Idioma",
        phone: "Teléfono",
        politicalActivities: "Actividades Política",
        ppa: "PPA",
        rut: "RUT",
        secondPreference: "Segunda Preferencia",
        socialWork: "Trabajo Social",
        sportActivities: "Actividades Deportivas",
        thirdPreference: "Tercera Preferencia",
        university: "Universidad"
      },
      languagesList: [
        { id: 7, name: "Alemán" },
        { id: 5, name: "Francés" },
        { id: 8, name: "Inglés" },
        { id: 1, name: "Portugués" },
        { id: "otro", name: "Otro(s)" }
      ],
      conditionsList: [
        { id: 1, name: "Estudiante - Primer Año" },
        { id: 2, name: "Estudiante - Segundo Año" },
        { id: 3, name: "Estudiante - Tercer Año" },
        { id: 4, name: "Estudiante - Cuarto Año" },
        { id: 5, name: "Estudiante - Quinto Año" },
        { id: 6, name: "Licenciado en Ciencias Jurídicas" },
        { id: 7, name: "Egresado" },
        { id: 8, name: "Abogado" }
      ]
    };
  },
  methods: {
    getField(key, value) {
      const array = [
        "areas",
        "condition",
        "languages",
        "university",
        "firstPreference",
        "secondPreference",
        "thirdPreference",
        "fourthPreference",
        "fifthPreference"
      ];
      if (!array.includes(key)) {
        return value;
      }

      const languages = [];
      const areas = [];

      switch (key) {
        case "areas":
          value.forEach(id => {
            const object = this.$store.state.applyForm["areasList"].find(
              area => area.id === id
            );
            areas.push(object.name);
          });

          value.name = areas.join(", ");
          break;
        case "university":
          value = this.$store.state.applyForm["universitiesList"].find(
            univerity => univerity.id === value
          );
          break;
        case "languages":
          value.forEach(id => {
            const object = this.languagesList.find(
              language => language.id === id
            );
            languages.push(object.name);
          });

          value.name = languages.join(", ");
          break;
        case "condition":
          value = this.conditionsList.find(condition => condition.id === value);
          break;
        case "firstPreference":
        case "secondPreference":
        case "thirdPreference":
        case "fourthPreference":
        case "fifthPreference":
          value = this.$store.state.applyForm["exhibitors"].find(
            exhibitor => exhibitor.id === value
          );
          break;
      }

      return typeof value == "undefined" ? [] : value.name;
    }
  }
};
</script>
