<template>
  <div class="container my-40">
    <div v-if="showForm" class="row">
      <div class="col-md-6">
        <div class="uc-form-group">
          <label for="name">Nombre</label>
          <input
            v-model.trim="form.name"
            type="text"
            :class="`uc-input-style ${required.name}`"
            @keydown="required.name = ''"
            v-tooltip="requiredTooltip.name"
            placeholder="Nombre"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="uc-form-group">
          <label for="email">Correo Electrónico</label>
          <input
            v-model.trim="form.email"
            type="text"
            :class="`uc-input-style ${required.email}`"
            @keydown="required.email = ''"
            v-tooltip="requiredTooltip.email"
            placeholder="Correo Electrónico"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="uc-form-group">
          <label for="subject">Asunto</label>
          <input
            v-model.trim="form.subject"
            type="text"
            :class="`uc-input-style ${required.subject}`"
            @keydown="required.subject = ''"
            v-tooltip="requiredTooltip.subject"
            placeholder="Asunto"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="uc-form-group">
          <label for="message">Mensaje</label>
          <textarea
            v-model.trim="form.message"
            :class="`uc-input-style ${required.message}`"
            @keydown="required.message = ''"
            v-tooltip="requiredTooltip.message"
            rows="4"
          ></textarea>
        </div>
      </div>
      <div class="col-md-12">
        <div class="text-right">
          <button class="uc-btn btn-primary btn-inline" type="button" @click="send">
            <span
              :class="`spinner-border spinner-border-sm mr-1 ${display}`"
              role="status"
              aria-hidden="true"
            ></span>
            {{ sendText }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="showResponse" :class="`uc-message ${responseClass} mb-32`">
      <a href="#!" class="uc-message_close-button" @click="showResponse = false; showForm = true">
        <i class="uc-icon">close</i>
      </a>
      <div class="uc-message_body">
        <h2 class="mb-24">
          <i class="uc-icon warning-icon">{{ responseClass == 'success' ? 'check_circle' : 'error'}}</i>
          {{ responseTitle }}
        </h2>
        <p class="no-margin">{{ responseBody }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);

export default {
  data() {
    return {
      display: "hide",
      sendText: "Enviar",
      form: {
        name: null,
        email: null,
        subject: null,
        message: null
      },
      required: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      requiredTooltip: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      showForm: true,
      responseClass: null,
      responseTitle: null,
      responseBody: null,
      showResponse: false
    };
  },
  methods: {
    checkForm(data) {
      let empty = false;

      for (const key in data) {
        const value = data[key];
        this.required[key] = "";
        this.requiredTooltip[key] = "";

        if (!value) {
          this.required[key] = "uc-alert error";
          this.requiredTooltip[key] = "Campo requerido";
          empty = true;
        }
      }

      if (!this.validEmail(data.email)) {
        this.required.email = "uc-alert error";
        let emailInvalidArray = this.requiredTooltip.email.split();
        emailInvalidArray.push("Correo Electrónico Inválido");
        emailInvalidArray = emailInvalidArray.filter(function(el) {
          return el != '';
        });
        this.requiredTooltip.email = emailInvalidArray.join("<br>");
        empty = true;
      }

      return empty;
    },
    send() {
      this.display = "block";
      this.sendText = "Enviando...";

      let data = {};

      for (const key in this.form) {
        data[key] = this.form[key];
      }

      if (this.checkForm(data)) {
        this.display = "hide";
        this.sendText = "Enviar";

        return;
      }

      axios
        .post(`${process.env.VUE_APP_REDES_ENDPOINT}/email`, data, {
          withCredentials: false
        })
        .then(response => {
          const data = response.data;

          if (data.success) {
            this.showResponse = true;
            this.responseClass = "success";
            this.responseTitle = "Correo Enviado Exitosamente";
            this.responseBody = "Gracias por ponerte en contacto con nosotros.";
          } else {
            this.showResponse = true;
            this.responseClass = "error";
            this.responseTitle = "Error Enviando Correo";
            this.responseBody =
              "Ha ocurrido un problema intentando enviar tu mensaje, por favor inténtalo más tarde.";
          }
        })
        .catch(() => {
          this.showResponse = true;
          this.responseClass = "error";
          this.responseTitle = "Error Enviando Correo";
          this.responseBody =
            "Ha ocurrido un problema intentando enviar tu mensaje, por favor inténtalo más tarde.";
        })
        .then(() => {
          this.showForm = false;
          this.display = "hide";
          this.sendText = "Enviar";

          for (const key in this.form) {
            this.form[key] = null;
          }
        });
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>

<style lang="sass">
.hide
  display: none
</style>
