<template>
  <div class="row">
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="rut">RUT</label>
        <input
          v-model="form.rut"
          v-mask="mask.rut"
          id="rut"
          @change="validateRut(form.rut);setField({id: 'rut', value: form.rut})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.rut}`"
          @keydown="clearRequired('rut')"
          v-tooltip="requiredTooltip.rut"
          placeholder="RUT"
        />
        <i class="uc-icon">recent_actors</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="name">Nombre Completo</label>
        <input
          v-model="form.name"
          id="name"
          @change="setField({id: 'name', value: form.name})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.name}`"
          @keydown="clearRequired('name')"
          v-tooltip="requiredTooltip.name"
          placeholder="Nombre Completo"
        />
        <i class="uc-icon">account_box</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="email">Correo</label>
        <input
          v-model="form.email"
          id="email"
          @change="setField({id: 'email', value: form.email})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.email}`"
          @keydown="clearRequired('email')"
          v-tooltip="requiredTooltip.email"
          placeholder="Correo"
        />
        <i class="uc-icon">contact_mail</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="phone">Número Telefónico</label>
        <input
          v-model="form.phone"
          v-mask="'+56 # #### ####'"
          id="phone"
          @change="setField({id: 'phone', value: form.phone})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.phone}`"
          @keydown="clearRequired('phone')"
          v-tooltip="requiredTooltip.phone"
          placeholder="+56 9 1234 5678"
        />
        <i class="uc-icon">phone_iphone</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="university">Universidad</label>
        <select
          :class="`uc-input-style w-icon-left ${required.university}`"
          v-tooltip="requiredTooltip.university"
          v-model="form.university"
          id="university"
          @change="setField({id: 'university', value: form.university});clearRequired('university')"
        >
          <option disabled value>Universidad</option>
          <option
            v-for="university in universitiesList"
            :key="university.id"
            :value="university.id"
          >{{university.name}}</option>
        </select>
        <i class="uc-icon">school</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="condition">Situación Actual</label>
        <select
          :class="`uc-input-style w-icon-left ${required.condition}`"
          v-tooltip="requiredTooltip.condition"
          v-model="form.condition"
          id="condition"
          @change="setField({id: 'condition', value: form.condition});clearRequired('condition')"
        >
          <option disabled value>Condición</option>
          <option
            v-for="condition in conditionsList"
            :key="condition.id"
            :value="condition.id"
          >{{condition.name}}</option>
        </select>
        <i class="uc-icon">work</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="ppa">
          PPA
          <small>(Promedio Ponderado Acumulado)</small>
        </label>
        <input
          v-model="form.ppa"
          v-mask="'#.##'"
          id="ppa"
          @change="setField({id: 'ppa', value: form.ppa})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.ppa}`"
          @keydown="clearRequired('ppa')"
          v-tooltip="requiredTooltip.ppa"
          placeholder="5.67"
        />
        <i class="uc-icon">grading</i>
      </div>
    </div>

    <ChooseTextarea
      title="¿Tienes Experiencia Laboral en Ámbito Jurídico?"
      icon="history_edu"
      placeholder="tus experiencias previas"
      fieldName="lawExperience"
      :model="form.lawExperience"
      store="applyForm"
      :clear="clear"
      :required="required.lawExperience"
      :requiredTooltip="requiredTooltip.lawExperience"
    />
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="areas">
          Áreas del Derecho de Interés
          <small>({{osKey}} + click para seleccionar varios)</small>
        </label>
        <select
          :class="`uc-input-style w-icon-left ${required.areas}`"
          @change="setField({id: 'areas', value: form.areas});clearRequired('areas')"
          v-tooltip="requiredTooltip.areas"
          v-model="form.areas"
          id="areas"
          multiple
        >
          <option v-for="area in areasList" :key="area.id" :value="area.id">{{area.name}}</option>
        </select>
        <i class="uc-icon">gavel</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="languages">
          Idiomas
          <small>({{osKey}} + click para seleccionar varios)</small>
        </label>
        <select
          :class="`uc-input-style w-icon-left ${required.languages}`"
          v-tooltip="requiredTooltip.languages"
          v-model="form.languages"
          id="languages"
          @change="setField({id: 'languages', value: form.languages});clearRequired('languages');chooseLanguage()"
          multiple
        >
          <option
            v-for="language in languagesList"
            :key="language.id"
            :value="language.id"
          >{{language.name}}</option>
        </select>
        <i class="uc-icon">translate</i>
      </div>
    </div>
    <div class="col-md-12" v-show="otherLanguage">
      <div class="uc-form-group">
        <label for="otherLanguage">Indique Idioma</label>
        <input
          v-model="form.otherLanguage"
          id="otherLanguage"
          @change="setField({id: 'otherLanguage', value: form.otherLanguage})"
          type="text"
          :class="`uc-input-style w-icon-left ${required.otherLanguage}`"
          @keydown="clearRequired('otherLanguage')"
          v-tooltip="requiredTooltip.otherLanguage"
          placeholder="Otro Idioma"
        />
        <i class="uc-icon">translate</i>
      </div>
    </div>
    <ChooseTextarea
      title="¿Realizas Actividades Académicas?"
      icon="description"
      placeholder="las actividades académicas que realices"
      fieldName="academicActivities"
      :model="form.academicActivities"
      store="applyForm"
      :clear="clear"
      :required="required.academicActivities"
      :requiredTooltip="requiredTooltip.academicActivities"
    />
    <ChooseTextarea
      title="¿Realizas Actividades Deportivas?"
      icon="sports_soccer"
      placeholder="las actividades deportivas que realices"
      fieldName="sportActivities"
      :model="form.sportActivities"
      store="applyForm"
      :clear="clear"
      :required="required.sportActivities"
      :requiredTooltip="requiredTooltip.sportActivities"
    />
    <ChooseTextarea
      title="¿Realizas Trabajos Sociales?"
      icon="accessibility"
      placeholder="los trabajos sociales que realices"
      fieldName="socialWork"
      :model="form.socialWork"
      store="applyForm"
      :clear="clear"
      :required="required.socialWork"
      :requiredTooltip="requiredTooltip.socialWork"
    />
    <ChooseTextarea
      title="¿Realizas Actividades Políticas?"
      icon="mic"
      placeholder="las actividades políticas que realices"
      fieldName="politicalActivities"
      :model="form.politicalActivities"
      store="applyForm"
      :clear="clear"
      :required="required.politicalActivities"
      :requiredTooltip="requiredTooltip.politicalActivities"
    />
    <PreferencesSelect
      position="Primera"
      fieldName="firstPreference"
      :required="required.firstPreference"
      :requiredTooltip="requiredTooltip.firstPreference"
      :exhibitors="exhibitors"
    />
    <PreferencesSelect
      fieldName="secondPreference"
      position="Segunda"
      :exhibitors="exhibitors"
      v-show="fields.firstPreference"
    />
    <PreferencesSelect
      fieldName="thirdPreference"
      position="Tercera"
      :exhibitors="exhibitors"
      v-show="fields.secondPreference"
    />
    <PreferencesSelect
      fieldName="fourthPreference"
      position="Cuarta"
      :exhibitors="exhibitors"
      v-show="fields.thirdPreference"
    />
    <PreferencesSelect
      fieldName="fifthPreference"
      position="Quinta"
      :exhibitors="exhibitors"
      v-show="fields.fourthPreference"
    />
  </div>
</template>

<script>
import ChooseTextarea from "@/components/ChooseTextarea.vue";
import PreferencesSelect from "@/components/PreferencesSelect.vue";
import VTooltip from "v-tooltip";
import Vue from "vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import VueMask from "v-mask";

Vue.use(VueMask);
Vue.use(VTooltip);

export default {
  components: {
    ChooseTextarea,
    PreferencesSelect
  },
  props: {
    clear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      osKey: "Ctrl",
      mask: {
        rut: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /[\dkK]/]
      },
      form: {
        academicActivities: "emptyValue",
        areas: [],
        condition: "",
        email: null,
        fifthPreference: null,
        firstPreference: null,
        fourthPreference: null,
        languages: [],
        lawExperience: "emptyValue",
        name: null,
        otherLanguage: "emptyValue",
        phone: null,
        politicalActivities: "emptyValue",
        ppa: null,
        rut: null,
        secondPreference: null,
        socialWork: "emptyValue",
        sportActivities: "emptyValue",
        thirdPreference: null,
        university: ""
      },
      areasList: [],
      exhibitors: [],
      universitiesList: [],
      languagesList: [
        { id: 7, name: "Alemán" },
        { id: 5, name: "Francés" },
        { id: 8, name: "Inglés" },
        { id: 1, name: "Portugués" },
        { id: "otro", name: "Otro(s)" }
      ],
      conditionsList: [
        { id: 1, name: "Estudiante - Primer Año" },
        { id: 2, name: "Estudiante - Segundo Año" },
        { id: 3, name: "Estudiante - Tercer Año" },
        { id: 4, name: "Estudiante - Cuarto Año" },
        { id: 5, name: "Estudiante - Quinto Año" },
        { id: 6, name: "Licenciado en Ciencias Jurídicas" },
        { id: 7, name: "Egresado" },
        { id: 8, name: "Abogado" }
      ]
    };
  },
  computed: {
    ...mapState("applyForm", ["fields", "required", "requiredTooltip"]),
    otherLanguage() {
      return this.form.languages.includes('otro');
    }
  },
  beforeMount() {
    if (navigator.appVersion.indexOf("Mac") != -1) {
      this.osKey = "cmd ⌘";
    }
  },
  watch: {
    otherLanguage(newValue) {
      this.form.otherLanguage = newValue ? null : "emptyValue";
    },
    clear() {
      if (this.clear) {
        mapMutations("applyForm", ["resetForm"]);
        this.form = {
          academicActivities: "emptyValue",
          areas: [],
          condition: "",
          email: null,
          fifthPreference: null,
          firstPreference: null,
          fourthPreference: null,
          languages: [],
          lawExperience: "emptyValue",
          name: null,
          phone: null,
          politicalActivities: "emptyValue",
          ppa: null,
          rut: null,
          secondPreference: null,
          socialWork: "emptyValue",
          sportActivities: "emptyValue",
          thirdPreference: null,
          university: ""
        };
      }
    }
  },
  methods: {
    ...mapMutations("applyForm", ["setField"]),

    updateExhibitors(value) {
      this.$store.commit("applyForm/exhibitors", []);
      const exhibitors = this.exhibitors.filter(
        exhibitor => exhibitor.show === false
      );

      for (const id in exhibitors) {
        exhibitors[id].show = true;
      }
      this.hanldeBiggestExhibitors(value);

      this.$store.commit("applyForm/exhibitors", this.exhibitors);
    },
    hanldeBiggestExhibitors() {
      const selected = [
        this.fields.firstPreference,
        this.fields.secondPreference,
        this.fields.thirdPreference,
        this.fields.fourthPreference,
        this.fields.fifthPreference
      ].filter(el => el != null);
      let count = 0;

      for (const id in selected) {
        const exhibitor = this.exhibitors.find(
          exhibitor => exhibitor.id === selected[id]
        );
        if (exhibitor.type === 3) {
          count++;
        }
        exhibitor.show = false;
      }

      const exhibitors = this.exhibitors.filter(
        exhibitor => exhibitor.type === 3
      );

      if (count >= 3) {
        for (const id in exhibitors) {
          exhibitors[id].show = false;
        }
      }
    },
    clearRequired(el) {
      if (this.$store.state.applyForm.required[el]) {
        this.$store.commit("applyForm/clearRequired", el);
      }
    },
    validateRut(value) {
      const required = { ...this.$store.state.applyForm.required };
      const requiredTooltip = {
        ...this.$store.state.applyForm.requiredTooltip
      };

      if (value.length < 10) {
        required.rut = "uc-alert error";
        requiredTooltip.rut = "RUT no válido";
        this.$store.commit("applyForm/required", { required, requiredTooltip });

        return;
      }

      const text = value.split("-");
      let rut = text[0];
      let m = 0;
      let dv = 1;

      for (; rut; rut = Math.floor(rut / 10)) {
        dv = (dv + (rut % 10) * (9 - (m++ % 6))) % 11;
      }

      if ((dv ? dv - 1 : "k") != text[1].toLowerCase()) {
        required.rut = "uc-alert error";
        requiredTooltip.rut = "RUT no válido";
      }

      this.$store.commit("applyForm/required", { required, requiredTooltip });
    },
    chooseLanguage() {
      this.$store.commit("applyForm/setField", {
        id: "otherLanguage",
        value: this.form.languages.includes("otro") ? null : "emptyValue"
      });
    }
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/universities`, {
        withCredentials: false
      })
      .then(response => {
        this.universitiesList = response.data;
        this.$store.commit("applyForm/universitiesList", this.universitiesList);
      });
    axios
      .get(
        `${process.env.VUE_APP_REDES_ENDPOINT}/areas?q[id_area_interes_ni]=1,6,7,9,10,12,13`,
        {
          withCredentials: false
        }
      )
      .then(response => {
        this.areasList = response.data;
        this.$store.commit("applyForm/areasList", this.areasList);
      });
    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/fair_exhibitors`, {
        withCredentials: false
      })
      .then(response => {
        for (const exhibitor in response.data) {
          response.data[exhibitor]["show"] = true;
        }
        this.exhibitors = response.data;
      });
  }
};
</script>

<style lang="sass">
.uc-modal
  padding-top: 50px !important

.uc-modal .uc-modal_content
  width: 60%

.uc-input-style.w-icon-left
  background-position: left 16px center !important
  padding: 12px 40px

textarea.uc-input-style.w-icon-left
  padding: 30px 40px

select[multiple="multiple"]
  height: auto

select[multiple="multiple"] + .uc-icon
  top: 70px

.w-icon-left + .uc-icon
  left: 10px
  position: absolute
  top: 42px
</style>
