<template>
  <div class="col-md-12">
    <div class="uc-form-group">
      <label :for="fieldName">{{ position }} Preferencia de Estudio para Entrevista</label>
      <select
        :class="`uc-input-style w-icon-left ${required}`"
        v-tooltip="requiredTooltip"
        v-model="value"
        :id="fieldName"
        @change="setField({id: fieldName, value});clearRequired(fieldName);updateExhibitors()"
      >
        <option disabled value>{{ position }} Preferencia</option>
        <option
          v-for="exhibitor in exhibitors"
          :key="exhibitor.id"
          :value="exhibitor.id"
          v-show="exhibitor.show"
        >{{exhibitor.name}}</option>
      </select>
      <i class="uc-icon">gavel</i>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    exhibitors: Array,
    fieldName: String,
    position: String,
    required: String,
    requiredTooltip: String,
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapMutations("applyForm", ["setField", "clearRequired"]),
    updateExhibitors() {
      this.$parent.updateExhibitors(this.value);
    }
  }
};
</script>
