<template>
  <main class="container my-40">
    <div class="row">
      <div v-for="(talk, key) in talks" :key="key" class="col mb-40 mr-40">
        <div class="title">
          <div class="d-flex align-items-center">
            <h3>{{ talk.title }} - {{ talk.schedule }}</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
        <div
          class="talk-img"
          :style="setImage(talk)"
          @click="openLink(talk.link)"
        ></div>
      </div>
    </div>
    <ExhibitorsCarousel />
  </main>
</template>

<script>
import ExhibitorsCarousel from "@/components/ExhibitorsCarousel.vue";
export default {
  components: { ExhibitorsCarousel },
  data() {
    return {
      talks: [
        {
          imgPath: "uploads/feria/Charlas_Feria del Trabajo 2023-02.png",
          link: "",
          title:
            "Internalización de la Profesión: ¿Es posible ejercer fuera de Chile?",
          schedule: "19 de abril, 11.30hrs",
        },
        {
          imgPath: "uploads/feria/Charlas_Feria del Trabajo 2023-03.png",
          link: "",
          title: "Energías renovables, alternativas novedosas y Derecho",
          schedule: "19 de abril, 15.30hrs",
        },
      ],
    };
  },
  methods: {
    setImage(talk) {
      let style = `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/${talk.imgPath}');
      `;

      if (talk.link) {
        style += "cursor:pointer";
      }

      return style;
    },
    openLink(link) {
      if (link.length) {
        window.open(link, "_blank");
      }
    },
  },
};
</script>

<style lang="sass">
.talk-img
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  height: 600px
  margin: auto
  width: 90%

.title
  min-height: 100px
</style>
