<template>
  <main class="container my-40">
    <div v-if="!$route.params.id" class="row">
      <div class="col-md-12 my-32">
        <div class="d-flex align-items-center">
          <h3>HISTORIA</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div class="col-md-12 justify-content-start">
        <p>La Feria del Trabajo de Derecho UC tuvo sus inicios el año 2012 con no más de 9 participantes, entre los cuales se encontraban prestigiosos estudios de abogados e instituciones públicas.</p>
        <p>La primera Feria Laboral destinada solo al área del Derecho, que convocó en un mismo evento a los mejores empleadores tanto públicos como privados, alumnos y abogados del país.</p>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 my-32">
        <div class="d-flex align-items-center">
          <h3>{{ fair.title }}</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div class="col-md-3">
        <div
          class="logo fairlogo"
          :style="`${getImgUrl(fair.logo)}height: ${fair.logoHeight || '300px'};`"
        ></div>
      </div>
      <div class="col-md-9 d-flex align-items-center justify-content-center">
        <p style="white-space: pre-wrap;" v-html="fair.description"></p>
      </div>

      <div v-if="fair.thanks" class="col-md-12 my-32">
        <div class="d-flex align-items-center">
          <h3>Agradecimientos</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div v-if="fair.thanks" class="col-md-12">
        <a :href="`${fair.gallery || '#!'}`" target="_blank">
          <div class="logo fairThanks" :style="`${getImgUrl(fair.thanks)}`"></div>
        </a>
      </div>

      <div v-if="fair.gallery || fair.video" class="col-md-12 my-32">
        <div class="d-flex align-items-center">
          <h3>Multimedia</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div
        v-if="fair.gallery"
        :class="`col-md-${fair.video ? 6 : 12} mb-20 d-flex align-items-center justify-content-center`"
      >
        <div class="col-md-12 label d-flex justify-content-center">
          <Label color="orange" text="GALERÍA DE FOTOS" :link="fair.gallery" target="_blank" />
        </div>
      </div>

      <div v-if="fair.video" :class="`col-md-${fair.gallery ? 6 : 12} mb-40 text-center`">
        <div class="tutorial-video embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            :src="`https://www.youtube.com/embed/${fair.video}`"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import fairs from "@/assets/json/History/Fairs.json";
import Label from "@/components/Label.vue";

export default {
  components: {
    Label
  },
  data() {
    return {
      fairs,
      fair: null
    };
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.fair = this.fairs[this.fairs.length - this.$route.params.id];
    }
  },
  beforeUpdate() {
    if (this.$route.params.id) {
      this.fair = this.fairs[this.fairs.length - this.$route.params.id];
    }
  },
  methods: {
    getImgUrl(file) {
      if (!file) {
        return;
      }

      let images = require.context(
        "@/assets/fairs/",
        false,
        /\.(png|jpg|jpeg)$/
      );
      let path = images(`./${file}`);

      return `background-image: url('${process.env.VUE_APP_FERIA_BASE_PATH}${path}');`;
    }
  }
};
</script>

<style lang="sass">
.logo
  background-position: center
  background-repeat: no-repeat
  background-size: contain

.logo.fairThanks
  height: 900px
</style>
